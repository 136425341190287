import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
// import CopyBlock from "../../components/Copy/CopyBlock"
// import InfoBar from '../../components/Utilities/InfoBar'
// import { array } from "prop-types"

class VsTop10 extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiTopXStats.edges
    const rdata = this.props.data.allSheetRonaldoTopXStats.edges

    const seasons = rdata.map(d => d.node.year).reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], [])

    return (

      <Layout>
        <SEO
          title={"vs Top 10 - Messi vs Ronaldo Goals and Stats vs Top 10 Teams"}
          description={`Compare Messi and Ronaldo's goals and assists against the Top 10 in league games throughout their respective careers.`}
          canonicalPath={`/all-time-stats/vs-top-10/`}
        />

        <h1>
          vs Top 10 <span className="sr-only">- Messi vs Ronaldo Goals and Stats vs Top 10 Teams</span>
        </h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'All', 'url': '/all-time-stats/vs-top-sides/' },
            { 'name': 'vs Top 3', 'url': '/all-time-stats/vs-top-3/' },
            { 'name': 'vs Top 5', 'url': '/all-time-stats/vs-top-5/' },
            { 'name': 'vs Top 10', 'url': 'none', 'active': true },
            { 'name': 'vs Bottom 10', 'url': '/all-time-stats/vs-bottom-10/' },
            { 'name': 'vs Bottom 3', 'url': '/all-time-stats/vs-bottom-3/' }
          ]}
        />

        { seasons.map(season => (
            <CompetitionBlock key={season} competition="vs Top 10" compTitle={season.replace(/-/g, '/')} type="all" subtitle="League games vs Top 10" modalSubTitle="All Time League Games" mdata={mdata.filter(d => d.node.year === season )} rdata={rdata.filter(d => d.node.year === season )} perfmsg="Since 2009/10" />
          ))
        }

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiTopXStats(filter: {competition: {eq: "vs Top 10"}}) {
      edges {
        node {
          id
          year
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
        }
      }
    }
    allSheetRonaldoTopXStats(filter: {competition: {eq: "vs Top 10"}}) {
      edges {
        node {
          id
          year
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
        }
      }
    }
  }
`

export default VsTop10
